hedgeStyle.css

/* Updated modal styles */
.modal-content {
  background-color: var(--background-color); /* Ensure it matches the app's background */
  color: var(--text-color); /* Text color for modal */
}

.modal-content p {
  background-color: var(--background-color); /* Ensure it matches the app's background */
  color: var(--text-color) !important; /* Text color for modal */
}

.modal-header,
.modal-footer {
  background-color: var(--highlight-color); /* Set highlight color for headers and footers */
  color: var(--text-color); /* Adjust text color to be visible */
}

.modal-body h5,
.modal-body p,
.modal-body ul {
  color: var(--text-color); /* Ensure body text color is correct */
}

.modal-body a {
  color: var(--highlight-color); /* Link color */
}

.popover-header,
.popover-body {
  background-color: var(--background-color); /* Popover background matches the modal */
  color: var(--text-color); /* Text color for popover */
}

.popover {
  max-width: 200px; /* Ensure popover doesn't expand too wide */
}

.btn-icon {
  font-size: 10px; /* Reduce button icon size */
  padding: 5px 10px; /* Adjust button padding for better fit */
}

.btn-sm {
  font-size: 10px;
  padding: 5px 10px;
}

.btn-close {
  background-color: transparent;
  border: none;
  position: relative;
  font-size: 20px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-close::before, .btn-close::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: red; /* Make the X red */
}

.btn-close::before {
  transform: rotate(45deg);
}

.btn-close::after {
  transform: rotate(-45deg);
}

.btn-close:hover {
  cursor: pointer;
  opacity: 0.8;
}

/* Ensure modal text is readable */
.modal-content {
  background-color: var(--background-color); /* Matches the overall app theme */
  color: var(--light-text-color); /* Light text color for contrast */
}

.modal-header,
.modal-footer {
  background-color: var(--highlight-color); /* Ensure header/footer are highlighted */
  color: var(--light-text-color); /* Light text color for visibility */
}

.modal-body p,
.modal-body h5,
.modal-body strong {
  color: var(--light-text-color); /* Ensure all body text is readable */
}

.modal-body a {
  color: var(--link-color); /* Highlight links with a more visible color */
}

pre {
  background-color: #f8f9fa; /* Light background for JSON data */
  color: #333; /* Ensure contrast against the light background */
  padding: 15px;
  border-radius: 5px;
  overflow-x: auto;
}

.badge {
  background-color: var(--badge-background-color); /* Use an appropriate background */
  color: var(--light-text-color); /* Ensure badge text is readable */
}

.event-details-modal .modal-body p {
  color: var(--light-text-color); /* Ensure all body text is readable */
}

/* -------------------------
   Removed Styles for Matrix Background
-------------------------- */

/* Removed .matrix-canvas styles */

/* Updated Landing Page Container */
.landing-page-container {
  position: relative;
  width: 100%;
  min-height: 100vh; /* Ensure it covers at least the viewport height */
}

/* Updated Authenticator Container */
.authenticator-container {
  position: relative;
  /* z-index: 1; */ /* Remove if not needed */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  /* height: 100%; */ /* Remove if causing layout issues */
}

/* -------------------------
   Rest of the Styles
-------------------------- */

/* TOTP Setup Container */
.totp-setup-container {
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  color: #ffffff; /* White text */
}

.totp-setup-container img {
  margin: 20px 0;
}

.totp-setup-container input {
  padding: 10px;
  width: 80%;
  max-width: 300px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
}

.totp-setup-container button {
  padding: 10px 20px;
  background-color: #19E421; /* Primary button color from theme */
  color: #121212; /* Primary button text color from theme */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.totp-setup-container button:hover {
  background-color: #16c317; /* Slightly darker on hover */
}

/* Main Authenticator Form Styling */
.authenticator-container main {
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  color: #ffffff; /* White text */
}

.authenticator-container main h1 {
  margin-bottom: 20px;
}

.authenticator-container main button {
  padding: 10px 20px;
  background-color: #19E421; /* Primary button color from theme */
  color: #121212; /* Primary text color from theme */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.authenticator-container main button:hover {
  background-color: #16c317; /* Slightly darker on hover */
}

/* SweetAlert Custom Styles */
.sweet-alert {
  /* Optional: Customize SweetAlert appearance if needed */
}

/* Responsive Design */
@media (max-width: 768px) {
  .authenticator-container {
    padding: 10px;
  }

  .totp-setup-container,
  .authenticator-container main {
    width: 90%;
    padding: 20px;
  }

  .totp-setup-container input {
    width: 100%;
  }
}
