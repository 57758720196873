/* TableStyles.css */

.styled-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .styled-table th, .styled-table td {
    border: 1px solid #ddd; /* Borders for cells */
    padding: 8px;
    color: white; /* White text color for all cells */
    font-size: 16px; /* Size 12 font for all cells */
  }
  
  .styled-table thead th {
    font-weight: bold; /* Bold font for headers */
    font-size: 20px; /* Size 16 font for headers */
    background-color: #333; /* Background color for headers */
  }
  
  .table-header {
    background-color: #333;
    color: white;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  
  .styled-table tbody tr:nth-child(odd) {
    background-color: #555; /* For striped effect, if desired */
  }
  