/* FinancialBackground.css */

.financial-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -3; /* Behind other fixed elements */
    overflow: hidden;
    background-color: #121212; /* Dark background for financial theme */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: none; /* Allow clicks to pass through */
  }
  
  /* Ticker Banner Styles */
  .ticker-container {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
  }
  
  .ticker {
    display: inline-block;
    padding-left: 100%;
    animation: ticker 20s linear infinite;
  }
  
  .ticker-item {
    display: inline-block;
    margin-right: 50px;
    font-family: 'Courier New', Courier, monospace;
    color: #00FF00; /* Bright green for financial tickers */
    font-size: 16px;
  }
  
  @keyframes ticker {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  /* Stock Chart Styles */
  .stock-chart {
    width: 100%;
    height: 200px;
    background-color: transparent;
  }
  
  /* Market Indicators Styles */
  .indicators-container {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .indicator-item {
    margin: 0 15px;
    display: flex;
    align-items: center;
  }
  
  .indicator-symbol {
    font-weight: bold;
    margin-right: 5px;
    color: #ffffff;
  }
  
  .indicator-status {
    font-size: 14px;
    color: #FFD700; /* Default color */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .ticker-item {
      font-size: 14px;
      margin-right: 30px;
    }
  
    .stock-chart {
      height: 150px;
    }
  
    .indicator-item {
      margin: 5px 10px;
    }
  }
  