/* colors.css */
:root {
    --primary-color: #3DDC84;          /* Calmer Green, easier on the eyes */
    --secondary-color: #007ACC;        /* Soft Blue */
    --background-color: #121B25;       /* Deep Dark Background, slightly lighter */
    --text-color: #E6E6E6;             /* Light Gray for better readability */
    --hover-background-color: #1A2A38; /* Subtle Hover Effect */
    --border-color: #1F2A37;           /* Slightly Lighter for better separation */
    --highlight-color: #2E8B57;        /* Calmer Green for Sidebar */
    --light-text-color: #f1f1f1;       /* Light text for contrast */
    --link-color: #8ab4f8;             /* Link color */
    --badge-background-color: #ff6f61; /* Example badge color */
    --desired-background-color: #121212; /* Black or dark grey */
}
  