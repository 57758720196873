/* TableComponent.css */
.table-container {
  overflow-x: auto;
  width: 100%;
  background-color: var(--background-color);
}

table {
  width: 100%;
  border-collapse: collapse;
  color: var(--primary-color);
  border: 1px solid var(--border-color);
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

th {
  background-color: var(--border-color);
  color: var(--primary-color);
}

tbody tr:hover {
  background-color: var(--hover-background-color);
}

.fa {
  color: var(--text-color);
}

.btn-icon {
  background: none;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.btn-icon:hover {
  background-color: var(--primary-color);
  color: var(--background-color);
}

.fixed-bottom-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: var(--primary-color);
  color: var(--background-color);
}

.fa-spin {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading {
  opacity: 0.6;
  pointer-events: none;
}
